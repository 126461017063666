<template>
    <div>
        <portal to="layout-default-header">
            <header-edit :title="group.name" />
        </portal>

        <side-nav-router-view
            :nav-items="navItems"
            :group="group"
        />
    </div>
</template>

<script>
import {Group} from '@/models/vue-mc/Group';
import HeaderEdit from '@/components/layouts/HeaderEdit';
import Role from '@/library/enumerations/Role';
import SideNavRouterView from '../layouts/SideNavRouterView';

export default {
    name: 'GroupSideNavRouterView',
    components: {HeaderEdit, SideNavRouterView},
    props: {
        group: {
            type: Group,
            required: true,
        },
    },
    data() {
        return {
            navItems: [
                {
                    location: this.group.getLocation('edit'),
                },
                {
                    location: this.group.getLocation('admins'),
                },
                {
                    location: this.group.getLocation('coaches'),
                },
                {
                    location: this.group.getLocation('clients'),
                },
            ],
        };
    },
};
</script>
